.top-navbar {
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0);
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;
	height: 80px;
	transition: all 0.2s ease-in;
}

.nav-bg {
	background-color: rgba(0, 0, 0, 1);
}

.inner-nav {
	width: 92%;
	max-width: 1076px;
	margin: auto;
	height: 80px;
	padding: 1em 0;
}

.inner-nav h2 span,
.overlay-menu .fs-2 span {
	color: rgb(6, 255, 214);
}

.inner-nav h2 a {
	text-decoration: none;
	color: white;
	cursor: pointer;
}

.nav-items a {
	color: white;
	text-decoration: none;
	padding: 0.5em 1em;
	border-radius: 12px;
}

.nav-items a:hover {
	color: white;
	text-decoration: none;
	background-color: rgba(255, 255, 255, 0.1);
}

.active-nav-link {
	position: relative;
}

.active-nav-link:before {
	content: '';
	position: absolute;
	background-color: rgb(6, 255, 214);
	height: 0.1em;
	bottom: 0.2em;
	left: 15%;
	width: 70%;
	z-index: 1;
}

.overlay-menu {
	position: fixed;
	height: 100vh;
	left: 0;
	top: 0;
	width: 60%;
	visibility: hidden;
	opacity: 0;
	transform: translateX(-50%);
	transition: all 0.2s;
	background-color: rgb(27, 45, 73);
	z-index: 10000;
}
.open-nav {
	visibility: unset;
	opacity: 1;
	transform: translateX(0);
}
.overlay-menu a {
	text-decoration: none;
}
.overlay-menu a:hover {
	border-bottom: 2px solid rgb(6, 255, 214);
}
@media (max-width: 600px) {
	.overlay-menu {
		width: 90%;
	}
	.top-navbar {
		height: 50px;
	}
	.inner-nav {
		font-size: 0.8em;
		height: 50px;
		padding: 0.2em 0;
		width: 90%;
	}
	.nav-items .col-auto {
		padding: 0;
	}
}
