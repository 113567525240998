.hero-wrapper {
	padding-top: 5rem;
	min-height: calc(100vh - 10rem);
}
.hero-headline {
	color: rgba(255, 255, 255, 0.7);
}

.about-link a {
	text-decoration: none;
	cursor: pointer;
	background-color: rgba(15, 51, 92, 0.3);
	background-image: radial-gradient(
		circle farthest-corner at 32.7% 82.7%,
		rgba(173, 0, 171, 0.45) 1%,
		rgba(15, 51, 92, 0.85) 100%
	);
	color: white;
	padding: 0.45em 1.3em;
	border-radius: 20px;
	border: 0.5px solid rgb(195, 195, 195);
	transition: all 0.1s;
}
.about-link a:hover {
	font-weight: 500;
	background-color: rgb(98, 54, 217);
	background-image: none;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	padding: 0.5em 1.4em;
	transition: all 0.1s;
}

.hero-img {
	width: 20em;
	height: 20em;
	background: white;
	border: 5px solid rgb(6, 255, 214);
	border-radius: 50%;
	object-fit: cover;
}

.external-links a {
	font-size: 1.2em;
	color: rgb(6, 255, 214);
}

.external-links a:hover {
	color: rgb(248, 105, 198);
}
