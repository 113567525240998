.bio-text {
	letter-spacing: 1px;
}

.bio-img {
	width: 100%;
	max-width: 275px;
}

.about-page-links {
	display: flex;
	justify-content: flex-start;
}

.about-page-links a {
	padding-right: 0.75em;
	font-size: 1.25em;
	margin-bottom: 1em;
	text-decoration: none;
	color: rgb(6, 255, 214);
}

.bio-text a {
	color: rgb(6, 255, 214);
	text-decoration: none;
}

.bio-text a:hover,
.about-page-links a:hover {
	color: rgb(248, 105, 198);
}

/* MEDIA QUERIES */
@media (min-width: 768px) {
	.bio-img {
		max-width: 325px;
	}
}
