@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Poppins', sans-serif;
}

body {
	background-color: #0f2027;
}

.main-page-container {
	background: #0f2027; /* fallback for old browsers */
	background: -webkit-linear-gradient(
		to right,
		#2c5364,
		#203a43,
		#0f2027
	); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to right,
		#2c5364,
		#203a43,
		#0f2027
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	min-height: 100vh;
	height: 100%;
	padding: 4rem 0;
}

.content-container {
	width: 95%;
	max-width: 1100px;
	margin: auto;
}

.about-wrapper,
.work-wrapper {
	padding-top: 5rem;
}

@media (min-width: 992px) {
	.about-wrapper {
		margin-bottom: 300px;
	}
}
